document.addEventListener("DOMContentLoaded", function () {
  const subcategories = document.querySelectorAll(
    ".hero-with-simple-bottom-subcategories__subcategory"
  );
  const subcategoryBackgrounds = document.querySelectorAll(
    ".hero-with-simple-bottom-subcategories__subcategory-background"
  );

  const adjustTitleHeights = () => {
    if (window.innerWidth < 1024) {
      return;
    }
    const titleElements = document.querySelectorAll(
      ".hero-with-simple-bottom-subcategories__subcategory-title"
    );
    let maxHeight = 0;

    titleElements.forEach((title) => {
      title.style.minHeight = "0";
    });

    titleElements.forEach((title) => {
      const height = title.offsetHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    if (maxHeight > 0) {
      titleElements.forEach((title) => {
        title.style.minHeight = `${maxHeight}px`;
      });
    }
  };

  adjustTitleHeights();
  window.addEventListener("resize", adjustTitleHeights);

  subcategories.forEach((subcategory, index) => {
    subcategory.addEventListener("mouseenter", function () {
      subcategoryBackgrounds.forEach((bg) =>
        bg.classList.remove(
          "hero-with-simple-bottom-subcategories__subcategory-background--active"
        )
      );

      const bgToActivate = subcategoryBackgrounds[index];
      if (bgToActivate) {
        bgToActivate.classList.add(
          "hero-with-simple-bottom-subcategories__subcategory-background--active"
        );
      }
    });

    subcategory.addEventListener("mouseleave", function () {
      const bgToDeactivate = subcategoryBackgrounds[index];
      if (bgToDeactivate) {
        bgToDeactivate.classList.remove(
          "hero-with-simple-bottom-subcategories__subcategory-background--active"
        );
      }
    });
  });
});
