import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

document.addEventListener("DOMContentLoaded", () => {
  const sliders = document.querySelectorAll(".hero-slider__slider");

  if (sliders.length > 0) {
    sliders.forEach((slider) => {
      if (!slider.classList.contains("swiper-initialized")) {
        const slidesToShow = parseInt(slider.dataset.slidesToShow, 10) || 1;
        const slidesToScroll = parseInt(slider.dataset.slidesToScroll, 10) || 1;
        const autoplay = slider.dataset.autoplay === "true";
        const autoplaySpeed =
          parseInt(slider.dataset.autoplaySpeed, 10) || 3000;
        const dots = slider.dataset.dots === "true";
        const infinite = slider.dataset.infinite === "true";
        const speed = parseInt(slider.dataset.speed, 10) || 750;
        const arrows = slider.dataset.arrows === "true";

        const swiper = new Swiper(slider, {
          direction: "horizontal",
          loop: infinite,
          autoplay: autoplay
            ? { delay: autoplaySpeed, disableOnInteraction: false }
            : false,
          slidesPerView: slidesToShow,
          slidesPerGroup: slidesToScroll,
          speed: speed,

          pagination: dots
            ? {
                el: slider.querySelector(".swiper-pagination"),
                clickable: true,
              }
            : false,

          navigation: arrows
            ? {
                nextEl: slider.querySelector(".swiper-button-next"),
                prevEl: slider.querySelector(".swiper-button-prev"),
              }
            : false,

          scrollbar: {
            el: ".swiper-scrollbar",
          },
        });

        swiper.on("slideChange", function () {});

        slider.classList.add("swiper-initialized");
      }
    });
  }
});
