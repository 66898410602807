document.addEventListener("DOMContentLoaded", function () {
  function handleOverlayClick(event) {
    event.currentTarget.classList.add("show-overlay-mobile");
  }

  document
    .querySelectorAll(
      ".block-editor-block-list__block.content-mason-grid-w-subtitle"
    )
    .forEach((blockElem, index) => {
      document
        .querySelectorAll(".content-mason-grid-w-subtitle-item")
        .forEach((item) => {
          item.addEventListener("click", handleOverlayClick);
        });
    });

  document.addEventListener("click", function (event) {
    const blockElement = document.querySelector(
      ".content-mason-grid-w-subtitle__controller"
    );
    if (blockElement !== null) {
      const isClickInside = blockElement.contains(event.target);

      if (!isClickInside) {
        document.querySelectorAll(".show-overlay-mobile").forEach((element) => {
          element.classList.remove("show-overlay-mobile");
        });
      }
    }
  });
});
