import Swiper from "swiper/bundle";
import "swiper/css/bundle";

document.addEventListener("DOMContentLoaded", () => {
  const mobileSwipers = document.querySelectorAll(
    ".blog-grid-of-image-and-preview-text__mobile .swiper-container"
  );

  if (mobileSwipers.length > 0) {
    mobileSwipers.forEach((container) => {
      let swiperInstance;

      const initSwiper = () => {
        if (
          window.innerWidth < 768 &&
          !container.classList.contains("swiper-initialized")
        ) {
          swiperInstance = new Swiper(container, {
            direction: "horizontal",
            slidesPerView: 1.2,
            centeredSlides: true,
            spaceBetween: 20,
            slidesPerGroup: 1,
            navigation: {
              nextEl: container.parentElement.querySelector(
                ".swiper-button-next"
              ),
              prevEl: container.parentElement.querySelector(
                ".swiper-button-prev"
              ),
            },
            pagination: {
              el: container.parentElement.querySelector(".swiper-pagination"),
              clickable: true,
            },
            loop: false,
          });
          container.classList.add("swiper-initialized");
        } else if (window.innerWidth >= 768 && swiperInstance) {
          swiperInstance.destroy(true, true);
          swiperInstance = null;
          container.classList.remove("swiper-initialized");
        }
      };

      initSwiper();

      window.addEventListener("resize", () => {
        initSwiper();
      });
    });
  }
});
