document.addEventListener("DOMContentLoaded", function () {
  const tabNavItems = document.querySelectorAll(".content-tabs__nav-li");
  const tabContents = document.querySelectorAll(".content-tabs__tab");
  const tabContentMobileItems = document.querySelectorAll(
    ".content-tabs__tab--mobile .content-tabs__tab-title"
  );
  const tabContentMobiles = document.querySelectorAll(
    ".content-tabs__tab--mobile .content-tabs__content"
  );

  function handleTabSwitching() {
    if (window.innerWidth > 1000) {
      if (tabNavItems.length > 0 && tabContents.length > 0) {
        tabNavItems.forEach((tabNav, index) => {
          tabNav.addEventListener("click", () => {
            tabContents.forEach((tabContent, i) => {
              tabNavItems[i].classList.remove("active");
              tabContent.classList.remove("active");
            });

            tabContents[index].classList.add("active");
            tabNav.classList.add("active");
          });
        });

        tabContents[0].classList.add("active");
        tabNavItems[0].classList.add("active");
      }
    } else {
      if (tabContentMobileItems.length > 0) {
        tabContentMobileItems.forEach((tabTitle, index) => {
          tabTitle.addEventListener("click", () => {
            const content = tabContentMobiles[index];

            if (content.style.maxHeight) {
              content.style.maxHeight = null;
              tabTitle.classList.remove("active");
            } else {
              tabContentMobiles.forEach((el, i) => {
                el.style.maxHeight = null;
                tabContentMobileItems[i].classList.remove("active");
              });

              content.style.maxHeight = content.scrollHeight + "px";
              tabTitle.classList.add("active");
            }
          });
        });
      }
    }
  }

  window.addEventListener("resize", handleTabSwitching);
  handleTabSwitching();
});
