import $ from "jquery";

$(function() {

    $('.hero-bucket-column').on({
        mouseenter: function() {
            const $siblings = $(this).parent().children();
            const siblingCount = $siblings.length;
            $siblings.each(function() {
                if (this !== $(this)) {
                    $(this).removeClass('is-open');
                    if (siblingCount >= 5) {
                        $(this).addClass('is-collapsed');
                    }
                }
            });
            $(this).addClass('is-open').removeClass('is-collapsed');
        },
        mouseleave: function() {
            const $siblings = $(this).parent().children();
            $siblings.each(function() {
                $(this).removeClass('is-open');
                $(this).removeClass('is-collapsed');
            });
        }
    });
});