import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

document.addEventListener("DOMContentLoaded", () => {
  if (window.innerWidth < 768) {
    const sliders = document.querySelectorAll(
      ".content-mason-grid-w-subtitle__controller"
    );
    sliders.forEach((slider) => {
      const slidesToShow = 1;
      const slidesToScroll = 1;
      const autoplay = false;
      const autoplaySpeed = 3000;
      const dots = true;
      const infinite = true;
      const speed = 750;
      const arrows = true;

      const swiper = new Swiper(slider, {
        direction: "horizontal",
        loop: infinite,
        autoplay: autoplay
          ? { delay: autoplaySpeed, disableOnInteraction: false }
          : false,
        slidesPerView: slidesToShow,
        slidesPerGroup: slidesToScroll,
        speed: speed,
        spaceBetween: 20,

        pagination: dots
          ? {
              el: slider.querySelector(".swiper-pagination"),
              clickable: true,
            }
          : false,

        navigation: arrows
          ? {
              nextEl: slider.querySelector(".swiper-button-next"),
              prevEl: slider.querySelector(".swiper-button-prev"),
            }
          : false,

        scrollbar: {
          el: ".swiper-scrollbar",
        },
      });

      swiper.on("slideChange", function () {});
    });
  }
});
