import $ from "jquery";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

$(function () {
  const windowWidth = $(window).width();

  $(".content-buckets-medium").each((index, blockElem) => {
    const align = $(blockElem).data("align");
    let contentHeight;
    const contentHeightAttr = $(blockElem).data("content-height");

    if (windowWidth < 1024) {
      //mobile and tablet will have the column height smaller
      contentHeight = eval(contentHeightAttr / 1.5);
    } else {
      contentHeight = contentHeightAttr;
    }

    //adding align class to the parent div
    $(blockElem).addClass("align" + (align ?? "full"));
  });
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.innerWidth < 768) {
    //in mobile the title should be placed over the image.
    const allItems = document.querySelectorAll(".content-bucket-item__link");
    allItems.forEach((item) => {
      const itemTitle = item.querySelector(".content-bucket-item__title");
      item.insertAdjacentHTML(
        "beforeend",
        `<span class="content-bucket-item__img-title">${itemTitle.textContent}</span>`
      );
    });

    const sliders = document.querySelectorAll(
      ".content-buckets-medium__controller"
    );
    sliders.forEach((slider) => {
      const slidesToShow = 1;
      const slidesToScroll = 1;
      const autoplay = false;
      const autoplaySpeed = 3000;
      const dots = true;
      const infinite = true;
      const speed = 750;
      const arrows = false;

      const swiper = new Swiper(slider, {
        direction: "horizontal",
        loop: infinite,
        autoplay: autoplay
          ? { delay: autoplaySpeed, disableOnInteraction: false }
          : false,
        slidesPerView: slidesToShow,
        slidesPerGroup: slidesToScroll,
        speed: speed,
        spaceBetween: 20,

        pagination: dots
          ? {
              el: slider.querySelector(".swiper-pagination"),
              clickable: true,
            }
          : false,

        navigation: arrows
          ? {
              nextEl: slider.querySelector(".swiper-button-next"),
              prevEl: slider.querySelector(".swiper-button-prev"),
            }
          : false,

        scrollbar: {
          el: ".swiper-scrollbar",
        },
      });

      swiper.on("slideChange", function () {});
    });
  }
});
