import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

document.addEventListener("DOMContentLoaded", () => {
  const sliderWrapper = document.querySelector(
    ".testimonial-carousel__slider-wrapper"
  );

  if (sliderWrapper) {
    const sliders = sliderWrapper.querySelectorAll(
      ".testimonial-carousel__slider"
    );

    sliders.forEach((slider) => {
      const slidesToShow = parseInt(slider.dataset.slidesToShow, 10) || 1;
      const slidesToScroll = parseInt(slider.dataset.slidesToScroll, 10) || 1;
      const autoplay = slider.dataset.autoplay === "true";
      const autoplaySpeed = parseInt(slider.dataset.autoplaySpeed, 10) || 3000;
      const dots = slider.dataset.dots === "true";
      const infinite = slider.dataset.infinite === "true";
      const rewind = slider.dataset.rewind === "true";
      const speed = parseInt(slider.dataset.speed, 10) || 750;
      const arrows = slider.dataset.arrows === "true";

      const swiper = new Swiper(slider, {
        direction: "horizontal",
        loop: infinite && !rewind,
        rewind: rewind && !infinite,
        autoplay: autoplay
          ? { delay: autoplaySpeed, disableOnInteraction: false }
          : false,
        slidesPerView: 1,
        slidesPerGroup: 1,
        speed: speed,
        spaceBetween: 45,
        pagination: dots
          ? {
              el: sliderWrapper.querySelector(".swiper-pagination"),
              clickable: true,
            }
          : false,

        navigation: arrows
          ? {
              nextEl: sliderWrapper.querySelector(".swiper-button-next"),
              prevEl: sliderWrapper.querySelector(".swiper-button-prev"),
            }
          : false,
        breakpoints: {
          1000: {
            slidesPerView: slidesToShow,
            slidesPerGroup: slidesToScroll,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 30,
          },
        },
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      });

      swiper.on("slideChange", function () {});
    });
  }
});
