import $ from "jquery";

$(function () {
  const windowWidth = $(window).width();

  $(".content-icon-list").each((index, blockElem) => {
    const align = $(blockElem).data("align");

    if (windowWidth < 1024) {
      //moves the button to a diffent position
      const contentButton = $(blockElem).find(".content-icon-list__button");
      let $movableButton = contentButton.detach();
      let $divListItems = $(blockElem).find(".content-icon-list__items");
      $movableButton.insertAfter($divListItems);
      $movableButton.addClass("is-mobile");
    }

    //adding align class to the parent div
    $(blockElem).addClass("align" + (align ?? "full"));
  });
});
