import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

document.addEventListener("DOMContentLoaded", () => {
  if (window.innerWidth < 768) {
    const sliders = document.querySelectorAll(".content-logo-grid__controller");
    sliders.forEach((slider) => {
      const totalSlides = slider.querySelectorAll(".swiper-slide").length;
      const desiredPages = 2;

      const slidesPerView = 1;
      let slidesPerPage = Math.ceil(totalSlides / desiredPages);
      let rows = slidesPerPage;

      if (rows > totalSlides) {
        rows = totalSlides;
      }

      const slidesPerGroup = slidesPerView * rows;

      const swiper = new Swiper(slider, {
        direction: "horizontal",
        loop: false,
        watchOverflow: true,
        autoplay: false,
        slidesPerView: slidesPerView,
        slidesPerGroup: slidesPerGroup,
        speed: 750,
        spaceBetween: 20,
        grid: {
          fill: "row",
          rows: rows,
        },
        pagination: {
          el: slider.querySelector(".swiper-pagination"),
          clickable: true,
        },
        navigation: {
          nextEl: slider.querySelector(".swiper-button-next"),
          prevEl: slider.querySelector(".swiper-button-prev"),
        },
      });
    });
  }
});
