import $ from "jquery";

$(function () {

    $('.tms-blocks-hero-video').each((index, blockElem) => {

        const videoElem = $(blockElem).find('.wp-block-video.hero-video__video').length === 1 ?
            $(blockElem).find('.wp-block-video.hero-video__video') //uploaded video
            : $(blockElem).find('.wp-block-embed.is-type-video');  // embed video

        if ($(videoElem).find('video').length === 0 && $(videoElem).find('iframe').length === 0) {

            $(blockElem).addClass('no-overlay');
            $(blockElem).find('.wp-block-tms-blocks-hero-video-overlay').addClass('no-overlay');
            $(blockElem).find('.hero-video-overlay__img').addClass('no-video');
            $(blockElem).find('.hero-video-overlay__text').html('Please, add a video');

        } else {

            const overlayElem = $(blockElem).find('.hero-video-overlay__img');
            if (overlayElem.length === 1) {

                $(overlayElem).height($(videoElem).height() + 'px');
                $(overlayElem).width($(videoElem).width() + 'px');
                $(overlayElem).css('left', 'calc(' + $(videoElem).css('padding-left') + ' + ' + $(videoElem).css('margin-left') + ')');

                function handleOverlayClick() {

                    $(videoElem).addClass('no-overlay');
                    $(videoElem).find('.hero-video-overlay__text').addClass('no-overlay');
                    $(videoElem).css('position', 'relative');
                    $(videoElem).css('z-index', '10');
                    if ($(videoElem).find('video').length === 1) {

                        $(videoElem).find('video').get(0).play();

                    } else {

                        const videoProviderClass = $(videoElem).attr("class").split(" ").filter(classN => classN.startsWith('is-provider-'))[0];
                        //TODO: Handle more video providers other than youtube and vimeo.
                        switch (videoProviderClass) {
                            case "is-provider-youtube":
                            case "is-provider-vimeo":
                            default:
                                const $iframe = $(videoElem).find('iframe:first-child');
                                const src = $iframe.attr('src');
                                const isPaused = src.includes('autoplay=0');
                                const newSrc = isPaused ? src.replace('autoplay=0', 'autoplay=1') : (src + '&autoplay=1');
                                $iframe.attr('src', newSrc);
                                break;
                        }
                        
                    }
                    //Need to handle the overlay click only once
                    $(blockElem).off('click', handleOverlayClick);
                }

                $(blockElem).on('click', handleOverlayClick);

            } else {
                $(blockElem).addClass('no-overlay');
                $(blockElem).find('.wp-block-tms-blocks-hero-video-overlay').addClass('no-overlay');
                $(videoElem).css('position', 'relative');
            }
        }
    });
});