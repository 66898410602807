import $ from "jquery";

$(function () {
  const windowWidth = $(window).width();

  $(".content-logo-grid").each((index, blockElem) => {
    const align = $(blockElem).data("align");
    //adding align class to the parent div
    $(blockElem).addClass("align" + (align ?? "full"));
  });
});
